import { Inertia } from "@inertiajs/inertia";
import { usePage } from "@inertiajs/inertia-react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import AktivPassivSVG from "../../Components/AktivPassivSVG";
import { useWindowSize } from "../../Hooks/CustomHooks";
import MainLayout from "../../Layouts/MainLayout";
import {
  chartsBase64State,
  existingPolicyState,
  isFastLaneState,
  kundenProfilDisplayState,
  newEsgProfilingState,
  portfolioResultState,
  risikoProfilingState,
  saveLastRouteState,
  umsetzungState,
  vertragsDetailsState
} from "../../Recoil/Atoms/PortfolioAtoms";
import { ESGState, hasExcursion, kundenprofilState } from "../../Recoil/Selectors/PortfolioSelectors";
import { createApplication } from "../../Services/ApplicationService";
import { samperformPrint, samperformPrintSwitchShift } from "../../Services/PrintService";
import { BREADCRUMBS_PORTFOLIO } from "../../Utils/Constants";
import { formatIntPercentage, percentageToInt, removeSVFromFondslist } from "../../Utils/PortfolioHelpers";
import { excursionState } from "../../Recoil/Atoms/PortfolioAtoms";
import { createApplicationForExcursion } from "../../Services/ApplicationService";

const Zusammenfassung = ({ env, visited }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = usePage().props.auth;
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [saveLastRoute, setSaveLastRouteState] = useRecoilState(saveLastRouteState);
  const resetVertragsDetails = useResetRecoilState(vertragsDetailsState);
  const resetRisikoProfiling = useResetRecoilState(risikoProfilingState);
  const resetNewEsgProfiling = useResetRecoilState(newEsgProfilingState);
  const resetUmsetzung = useResetRecoilState(umsetzungState);
  const resetPortfolioResult = useResetRecoilState(portfolioResultState);
  const resetFastLane = useResetRecoilState(isFastLaneState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const vertragsDetails = useRecoilValue(vertragsDetailsState);
  const calculatedKundenProfil = useRecoilValue(kundenprofilState);
  const { versichererTarif, beitragshohe, vorgangsnummer, jahren, zahlweise, name, vorname, nutze_sicherungsvermoegen, vertragsnummer } = vertragsDetails;
  const kundenProfilDisplay = useRecoilValue(kundenProfilDisplayState);
  const esgProfil = useRecoilValue(ESGState);
  const umsetzung = useRecoilValue(umsetzungState);
  const portfolioResult = useRecoilValue(portfolioResultState);
  const chartsBase64 = useRecoilValue(chartsBase64State);
  const newEsgProfiling = useRecoilValue(newEsgProfilingState);
  const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);
  const tableData = portfolioResult && removeSVFromFondslist(portfolioResult.Aufteilung_nach_Fonds);
  const sicherungsvermoegen = portfolioResult && portfolioResult.Aufteilung_nach_Fonds.find((item) => item.ISIN === "DE00ERGOSV00")?.Gewicht;
  const [existingPolicy, setExistingPolicy] = useRecoilState(existingPolicyState);
  const [calcResult, setCalcResult] = useState(existingPolicy?.selected_years === 5 ? existingPolicy?.calcResult5 : existingPolicy?.calcResult3);
  const excursion = useRecoilValue(excursionState);
  const hasExcursionValue = useRecoilValue(hasExcursion);

  let legend_etf = "";
  switch (umsetzung) {
    case "Aktiv":
      legend_etf = "Fonds und ETF";
      break;
    case "Passiv":
      legend_etf = "nur ETF";
      break;
    case "nur Aktiv":
      legend_etf = "nur aktive Fonds";
      break;
    default:
      break;
  }
  useEffect(() => {
    if (
      !isFastLane &&
      (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined || umsetzung === undefined || !versichererTarif || !beitragshohe || !jahren || !zahlweise)
    ) {
      Inertia.get("/");
    }
  }, []);

  const onNext = () => {
    resetVertragsDetails();
    resetRisikoProfiling();
    resetNewEsgProfiling();
    resetUmsetzung();
    resetPortfolioResult();
    resetFastLane();
    Inertia.get(route("portfolio.risikoProfiling"));
  };

  const onBack = () => Inertia.get(route("portfolio.portfoliovorschlag"));

  const getFonds = (fonds) => {
    return fonds
      .filter((fond) => percentageToInt(fond.Gewicht) > 0)
      .sort((a, b) => {
        return b.Gewicht - a.Gewicht;
      })
      .map((filteredFond, index) => ({
        index: index + 1,
        isin: filteredFond.ISIN,
        name: filteredFond.Fondsname,
        percentage: formatIntPercentage(filteredFond.Gewicht)
      }));
  };

  const handleOpenDialog = () => {
    setDialogOpened(true);
  };

  const handleCloseDialog = () => {
    setDialogOpened(false);
  };

  const navigateLogin = () => {
    setSaveLastRouteState(true);
    Inertia.get(route("login"));
    setDialogOpened(false);
  };

  const navigateRegister = () => {
    setSaveLastRouteState(true);
    Inertia.get(route("register"));
    setDialogOpened(false);
  };

  const getAnswer = (question) => {
    return newEsgProfiling.question2.answer === "Ja" && newEsgProfiling.question3.answer !== "neutral_nachhaltig" && newEsgProfiling.question7.answer !== "branche"
      ? newEsgProfiling[question].answer || "nicht beantwortet"
      : "nicht beantwortet";
  };

  const getEsgDataPayload = () => {
    let q3, q5;
    if (newEsgProfiling.question3.answer === "neutral_nachhaltig") {
      q3 = "neutral nachhaltig - es sollen nur die wichtigsten Risiken aus Nachhaltigkeitsfaktoren berücksichtigt werden";
    } else if (newEsgProfiling.question3.answer === "nachhaltig") {
      q3 = "nachhaltig - es soll ein Beitrag zur Nachhaltigkeit erreicht werden";
    } else {
      q3 = "stark nachhaltig (ESG plus) - es soll ein wesentlicher Beitrag zur Nachhaltigkeit erreicht werden";
    }

    if (newEsgProfiling.question7.answer !== "branche") {
      q5 = `Ich habe bestimmte Präferenzen in den Bereichen \n Umwelt: ${newEsgProfiling.question5.answer} \n Soziales: ${newEsgProfiling.question6.answer}`;
    } else {
      q5 = `Meine Präferenzen sind abhängig von der jeweiligen Branche. \n So ist mir bekannt, dass z.B. bei Energieerzeugern der Aspekt CO2 eine wichtige Rolle spielt oder bei Unternehmen der Chemiebranche z.B. Giftmüll. Bei Dienstleistungsunternehmen wie z.B. Versicherungsunternehmen stehen dagegen faire Kundenbehandlung oder bei Internetunternehmen wie z.B. Facebook Datenschutz und CO2 im Vordergrund.`;
    }

    return {
      q1: newEsgProfiling.question1.answer,
      q2: newEsgProfiling.question2.answer,
      q3: newEsgProfiling.question2.answer === "Ja" ? q3 : "nicht beantwortet",
      q4: newEsgProfiling.question2.answer === "Ja" ? newEsgProfiling.question4[0].label : "nicht beantwortet",
      q5: newEsgProfiling.question2.answer === "Ja" && newEsgProfiling.question3.answer !== "neutral_nachhaltig" ? q5 : undefined,
      rq1: getAnswer("rahmen_question1"),
      rq2: getAnswer("rahmen_question2"),
      rq3: getAnswer("rahmen_question3"),
      rq4: getAnswer("rahmen_question4"),
      rq5: getAnswer("rahmen_question5"),
      rq6: getAnswer("rahmen_question6"),
      rq7: getAnswer("rahmen_question7"),
      rq8: getAnswer("rahmen_question8"),
      rq9: getAnswer("rahmen_question9"),
      rq10: getAnswer("rahmen_question10"),
      rq11: getAnswer("rahmen_question11"),
      rq12: getAnswer("rahmen_question12"),
      rq13: getAnswer("rahmen_question13"),
      rq14: getAnswer("rahmen_question14"),
      rq15: getAnswer("rahmen_question15"),
      rq16: getAnswer("rahmen_question16"),
      rq17: getAnswer("rahmen_question17"),
      uq1: getAnswer("umwelt_question1"),
      uq2: getAnswer("umwelt_question2"),
      uq3: getAnswer("umwelt_question3"),
      uq4: getAnswer("umwelt_question4"),
      uq5: getAnswer("umwelt_question5"),
      uq6: getAnswer("umwelt_question6"),
      uq7: getAnswer("umwelt_question7"),
      sq1: getAnswer("soziale_question1"),
      sq2: getAnswer("soziale_question2"),
      sq3: getAnswer("soziale_question3"),
      sq4: getAnswer("soziale_question4"),
      q8:
        newEsgProfiling.question2.answer === "Ja" && newEsgProfiling.question3.answer !== "neutral_nachhaltig" && newEsgProfiling.question7.answer !== "branche"
          ? newEsgProfiling.question8.answer
          : "nicht beantwortet"
    };
  };

  const getRequestPayload = () => {
    const resultFonds = getFonds(removeSVFromFondslist(tableData));

    let etf = {
      label: umsetzung,
      description: legend_etf,
      image: ""
    };

    switch (umsetzung) {
      case "Aktiv":
        etf.image = "ETF_Aktiv";
        break;
      case "Passiv":
        etf.image = "ETF_Pasiv";
        break;
      case "nur Aktiv":
        etf.image = "kurve_nur_aktive_fonds";
        break;
      default:
    }

    const payload = {
      customer: {
        firstname: vorname,
        lastname: name
      },
      user: user
        ? {
            firstname: user.vorname,
            lastname: user.name,
            profession: user.unternehmen,
            street_housenumber: user.strasse,
            zip: user.plz,
            city: user.ort,
            email: user.email,
            logo: user.logo
          }
        : {},
      portfolioResult: portfolioResult,
      calculation: {
        date: format(new Date(), "dd.MM.yyyy"),
        input: {
          risk_profiling: {
            q1: risikoProfiling.question1[0].value,
            q2: risikoProfiling.question2[0].label,
            q3: risikoProfiling.question3.answer === 3 ? "Ja" : "Nein",
            q4: risikoProfiling.question4.answer === 3 ? "Ja" : "Nein",
            q5: risikoProfiling.question5[0].label,
            q6: risikoProfiling.question6[0].label
          },
          esg_profiling: getEsgDataPayload(),
          application: {
            tarif: versichererTarif.label,
            beitragszahlweise: zahlweise.label,
            beitragszahldauer: jahren.label,
            vorgangsnummer: vorgangsnummer,
            vertragsnummer: vertragsnummer,
            nutze_sicherungsvermoegen: nutze_sicherungsvermoegen,
            sicherungsvermoegen: sicherungsvermoegen ? sicherungsvermoegen * 100 + "%" : "",
            beitragshoehe: Number(beitragshohe).toLocaleString("de", { minimumFractionDigits: 2 })
          }
        },
        result: {
          charts: {
            risikoklassen: chartsBase64.circularChart,
            assetklassen: chartsBase64.chart1,
            einzelfonds: chartsBase64.chart2
          },
          rendite: (portfolioResult.Erwartete_Rendite * 100).toFixed(2).replace(".", ","),
          risiko: (portfolioResult.Erwartetes_Risiko * 100).toFixed(2).replace(".", ","),
          risk_profile: {
            label: kundenProfilDisplay.imageTitle,
            mdd: kundenProfilDisplay.mdd,
            image: kundenProfilDisplay.image,
            riskProfileCustom:
              calculatedKundenProfil?.kundenprofil !== kundenProfilDisplay?.kundenprofil && !isFastLane
                ? "Hinweis: Das Kunden/Risikoprofil wurde manuell geändert und entspricht nicht dem ermittelten Ergebnis des Risikoprofilings!"
                : ""
          },
          esg_profile: {
            label: esgProfil.displayEsg,
            image: esgProfil.image
          },
          etf: etf,
          fonds: resultFonds
        }
      },
      existingPolicy: existingPolicy
    };

    return payload;
  };

  const onPrint = async () => {
    const printData = getRequestPayload();
    setIsLoading(true);
    await samperformPrint(printData);

    if (vertragsDetails.canSwitchShift && vertragsDetails.versichererTarif.label === vertragsDetails.versichererTarifAlt.label) await samperformPrintSwitchShift(printData);

    setIsLoading(false);
  };

  const onCreateApplication = async () => {
    const printData = getRequestPayload();
    setIsLoading(true);
    let res = null;
    if (hasExcursionValue) {
      const fondsList = portfolioResult.Aufteilung_nach_Fonds.map((fond) => {
        if (fond.Gewicht > 0) return { isin: fond.ISIN, anteile: Math.round(fond.Gewicht * 100, 0) };
      }).filter((fond) => fond !== undefined);
      res = await createApplicationForExcursion(excursion.callback, { fonds: fondsList });
    } else {
      res = await createApplication(printData);
    }
    if (res.success)
      enqueueSnackbar(res.message, {
        variant: "success"
      });
    else
      enqueueSnackbar(res.message, {
        variant: "error"
      });
    setIsLoading(false);
  };

  const onSave = async () => {
    const archive = getRequestPayload();
    Inertia.post(
      route("archive.store"),
      { archive },
      {
        onStart: () => setIsLoading(true),
        onError: () => {
          enqueueSnackbar("Fehler beim Speichern der Berechnung", {
            variant: "error"
          });
        },
        onFinish: () => {
          enqueueSnackbar("Berechnung wurde gespeichert", {
            variant: "success"
          });
          setIsLoading(false);
        }
      }
    );
  };

  const Tile = (props) => {
    return (
      <Grid
        xs={8}
        sm={8}
        md={4}
        lg={2}
        xl={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
        <Box sx={{ height: "100%", width: "100%", m: "0px", p: "16px", border: "1px solid #EBEBEB", borderRadius: "6px", bgcolor: "#FFFFFF" }}>
          <Box>{props.title}</Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {props.subtitle}
          </Typography>
          {props.subject?.image && (
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", bgcolor: "#EBEBEB", py: "20px", width: "100%", borderRadius: "20px", my: "5px" }}>
              <img src={`/images/${props.subject.image}.png`} alt="" height="101px" />
            </Box>
          )}
          {props.image && (
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", bgcolor: "#EBEBEB", py: "20px", width: "100%", borderRadius: "20px", my: "5px" }}>{props.image}</Box>
          )}
          {props.main && props.main}
          {props.legend && props.legend}
          <Typography>{props.subject?.imageSubtitle}</Typography>
        </Box>
      </Grid>
    );
  };

  const Berechnungsinfos = () => {
    const boxSX = {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      mt: "0px",
      mb: "0px",
      pt: "5px",
      pb: "5px"
    };
    return (
      <Box sx={{ mt: "16px" }}>
        <Box sx={{ ...boxSX, borderBottom: "1px solid #EBEBEB" }}>
          <Typography>Beitragszahlweise:</Typography>
          <Typography>{zahlweise?.value}</Typography>
        </Box>
        <Box sx={{ ...boxSX, borderBottom: "1px solid #EBEBEB" }}>
          <Typography>Beitragszahldauer:</Typography>
          <Typography>{jahren?.value}</Typography>
        </Box>
        <Box sx={{ ...boxSX, borderBottom: "1px solid #EBEBEB" }}>
          <Typography>Beitragshöhe:</Typography>
          <Typography>{`${Number(beitragshohe).toLocaleString("de", { minimumFractionDigits: 2 })} EUR`}</Typography>
        </Box>
        <Box sx={{ ...boxSX, borderBottom: "1px solid #EBEBEB" }}>
          <Typography>Erw. Rendite:</Typography>
          <Typography>{`${(portfolioResult?.Erwartete_Rendite * 100).toFixed(2).replace(".", ",")}% `}</Typography>
        </Box>
        <Box sx={{ ...boxSX }}>
          <Typography>Erw. Risiko:</Typography>
          <Typography>{`${(portfolioResult?.Erwartetes_Risiko * 100).toFixed(2).replace(".", ",")}% `}</Typography>
        </Box>
        {sicherungsvermoegen > 0 && (
          <Box sx={{ ...boxSX, borderTop: "1px solid #EBEBEB" }}>
            <Typography>Sicherungsvermögen:</Typography>
            <Typography>{formatIntPercentage(sicherungsvermoegen * 100)}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  // createApplicationButtonHidden={!(versichererTarif?.label.startsWith('Ergo') && env.ca_ergo)} //ERGO CR - Sicherungsvermögen
  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      isLoading={isLoading}
      subHeaderTitle="Zusammenfassung"
      rightBtnName="neue Berechnung"
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
      createApplicationButtonHidden={!hasExcursionValue && !(versichererTarif?.label.startsWith("Ergo") && env.ca_ergo)} //ERGO CR - Sicherungsvermögen
      createApplicationButtonLabel={versichererTarif?.label.startsWith("InterRisk") ? "zurück zum Tarifrechner" : "Antrag"}
      onCreateApplicationBtnClick={onCreateApplication}
      printButtonHidden={false}
      onPrintBtnClick={onPrint}
      saveButtonHidden={false}
      onSaveBtnClick={user ? onSave : handleOpenDialog}>
      <Box sx={{ alignItems: "center" }}>
        <Grid container="true" spacing={1} columns={calcResult?.beginn ? 10 : 8} justifyContent="center">
          {calcResult?.beginn && (
            <Tile
              title="Bestehendes Portfolio"
              subtitle="Auswertung"
              subject={{
                image: "Bestehender_Vertrag",
                imageSubtitle:
                  calcResult?.perf_pro_jahr?.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
                  "% p.a.  -  " +
                  calcResult?.mdd?.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
                  "% MDD"
              }}
            />
          )}
          <Tile title="Kundenprofil" subtitle={kundenProfilDisplay?.imageTitle} subject={kundenProfilDisplay} />
          <Tile title="ESG" subtitle={esgProfil?.displayEsg} subject={esgProfil} />
          <Tile title="Tarif" subtitle={versichererTarif?.label} main=<Berechnungsinfos /> />
          <Tile title="Management-Stil" subtitle="Aktiv / Passiv" legend={legend_etf} image=<AktivPassivSVG width="90" height="100" icon={umsetzung} umsetzung={umsetzung} /> />
        </Grid>
        <br />
        {portfolioResult && (
          <TableContainer component={Box} sx={{ maxHeight: 320, bgcolor: "#FFFFFF" }}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>ISIN</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Fondsname</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Assetklasse</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Anteil</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .sort((a, b) => {
                    return b.Gewicht - a.Gewicht;
                  })
                  .map((filteredFond, index) => (
                    <TableRow key={`fond_${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{filteredFond.ISIN}</TableCell>
                      <TableCell>{filteredFond.Fondsname}</TableCell>
                      <TableCell>{filteredFond.Assetklasse}</TableCell>
                      <TableCell>{formatIntPercentage(filteredFond.Gewicht)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Dialog open={dialogOpened} onClose={handleCloseDialog}>
        <DialogTitle>Speichern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Die Funktion zum Speichern steht nur für registrierte Benutzer zur Verfügung. Falls Sie schon registriert sind, loggen Sie sich bitte ein. Oder registrieren Sie sich gleich jetzt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ bgcolor: "black", color: "white", textTransform: "none", "&:hover": { bgcolor: "black" } }} onClick={handleCloseDialog}>
            Abbruch
          </Button>
          <Button variant="contained" sx={{ bgcolor: "#C94141", color: "white", textTransform: "none", "&:hover": { bgcolor: "#C94141" } }} onClick={navigateLogin}>
            Login
          </Button>
          <Button variant="contained" sx={{ bgcolor: "#C94141", color: "white", textTransform: "none", "&:hover": { bgcolor: "#C94141" } }} onClick={navigateRegister}>
            Registrierung
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default Zusammenfassung;
