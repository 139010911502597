import React, { useEffect } from "react";
import { Inertia } from "@inertiajs/inertia";
import MainLayout from "../../Layouts/MainLayout";
import { Box, Tooltip, IconButton } from "@mui/material";
import SelectComponent from "../../Components/Controls/SelectComponent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InputComponent from "../../Components/Controls/InputComponent";
import SwitchComponent from "../../Components/Controls/SwitchComponent";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  portfolioResultState,
  comparisonResultState,
  risikoProfilingState,
  umsetzungState,
  vertragsDetailsState,
  tarifListState,
  kundenProfilDisplayState,
  isFastLaneState,
  userLicenceState,
  zahlweiseListState,
  minBeitragState,
  excursionState
} from "../../Recoil/Atoms/PortfolioAtoms";
import { BREADCRUMBS_PORTFOLIO, INFO_TEXTS } from "../../Utils/Constants";
import { canUseSicherungsvermoegen, canSeeSicherungsvermoegen } from "../../Utils/PortfolioHelpers";
import { ESGState, zahlweiseLabelState } from "../../Recoil/Selectors/PortfolioSelectors";
import { usePage } from "@inertiajs/inertia-react";
import { calculate, compare } from "../../Services/PortfolioService";
import { useSnackbar } from "notistack";
import { hasExcursion } from "../../Recoil/Selectors/PortfolioSelectors";

const Vertragsdetails = ({ visited }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = usePage().props.auth;
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const kundenProfilDisplay = useRecoilValue(kundenProfilDisplayState);
  const esg = useRecoilValue(ESGState);
  const umsetzung = useRecoilValue(umsetzungState);
  const zahlweiseLabel = useRecoilValue(zahlweiseLabelState);
  const [vertragsDetails, setVertragsDetails] = useRecoilState(vertragsDetailsState);
  const userLicence = useRecoilValue(userLicenceState);
  const [portfolioResult, setPortfolioResult] = useRecoilState(portfolioResultState);
  const [comparisonResult, setComparisonResult] = useRecoilState(comparisonResultState);
  const [zahlweiseList, setZahlweiseList] = useRecoilState(zahlweiseListState);
  const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);
  const [minBeitrag, setMinBeitrag] = useRecoilState(minBeitragState);
  const beitragszahldauerOptions = [];
  const tarifList = useRecoilValue(tarifListState);
  const excursion = useRecoilValue(excursionState);
  const hasExcursionValue = useRecoilValue(hasExcursion);

  let beitrag = 0;
  if (vertragsDetails.zahlweise?.value === "einmalig") {
    beitrag = vertragsDetails?.versichererTarif?.mb_einmal;
  }
  if (vertragsDetails.zahlweise?.value === "laufend") {
    beitrag = vertragsDetails?.versichererTarif?.mb_laufend;
  }

  const minBeitragLabel = "mind. " + beitrag?.toLocaleString("de-DE") + " EUR";
  const minBeitragValue = beitrag;

  let rightBtnDisabled = !vertragsDetails.versichererTarif || !vertragsDetails.beitragshohe || !vertragsDetails.jahren || !vertragsDetails.zahlweise || vertragsDetails.beitragshohe < minBeitragValue;

  for (let index = 2; index <= 40; index++) {
    beitragszahldauerOptions.push({
      value: `${index} Jahre`,
      label: `${index} Jahre`,
      searchParam: index
    });
  }
  beitragszahldauerOptions.push({
    value: ">40 Jahre",
    label: ">40 Jahre",
    searchParam: 41
  });

  function hasPermission(permission) {
    const { allPermissions } = usePage().props.auth;
    if (allPermissions) return allPermissions.filter((item) => item.name === permission).length > 0;
    else return false;
  }

  useEffect(() => {
    if (excursion) {
      setVertragsDetails({
        ...vertragsDetails,
        versichererTarif: tarifList.find((tarif) => tarif.label === excursion.tarif),
        zahlweise: zahlweiseList.find((zahlweise) => zahlweise.label === excursion.zahlweise),
        jahren: beitragszahldauerOptions.find((beitragszahldauer) => beitragszahldauer.label === excursion.beitragszahldauer),
        beitragshohe: excursion.beitragshoehe
      });
    }
  }, [excursion]);

  useEffect(() => {
    let zahlweiseItems = [];
    if (vertragsDetails.versichererTarif?.mb_laufend > 0) zahlweiseItems.push({ value: "laufend", label: "laufend" });
    if (vertragsDetails.versichererTarif?.mb_einmal > 0) zahlweiseItems.push({ value: "einmalig", label: "einmalig" });
    setZahlweiseList(zahlweiseItems);
    if (zahlweiseItems?.length === 1) {
      setVertragsDetails({ ...vertragsDetails, zahlweise: zahlweiseItems[0] });
    }
  }, [vertragsDetails.versichererTarif]);

  useEffect(() => {
    if (zahlweiseList?.length === 1) {
      setVertragsDetails({ ...vertragsDetails, zahlweise: zahlweiseList[0] });
    }
    if (!isFastLane && (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined || umsetzung === undefined)) {
      Inertia.get("/");
    }
  }, []);

  const onNext = async () => {
    const position = vertragsDetails.versichererTarif.value.indexOf(" ");
    const insuranceCompanyId = vertragsDetails.versichererTarif.value.substring(0, position);
    const tarif = vertragsDetails.versichererTarif.value.substring(position + 1);
    const resultPortfolio = await calculate(
      insuranceCompanyId,
      tarif,
      vertragsDetails.jahren.value,
      vertragsDetails.zahlweise.value,
      kundenProfilDisplay.kundenprofil,
      umsetzung,
      esg.esg,
      canSeeSicherungsvermoegen(userLicence) && canUseSicherungsvermoegen(kundenProfilDisplay, vertragsDetails.versichererTarif, esg) ? vertragsDetails.nutze_sicherungsvermoegen : false
    );

    setPortfolioResult(resultPortfolio);
    Inertia.get(route("portfolio.portfoliovorschlag"));
  };

  const onBack = () => {
    if (isFastLane) {
      Inertia.get(route("portfolio.fastLane"));
    } else {
      Inertia.get(route("portfolio.etf"));
    }
  };

  const onComparison = async () => {
    console.log("vertragsDetails", vertragsDetails);
    const resultComparison = await compare(vertragsDetails.jahren.value, vertragsDetails.zahlweise.value, kundenProfilDisplay.kundenprofil, umsetzung, esg.esg);
    if (resultComparison) {
      setPortfolioResult(resultComparison);
      setComparisonResult(resultComparison);
      Inertia.get(route("portfolio.comparison"));
    } else {
      enqueueSnackbar("Vergleich nicht möglich!", {
        variant: "error"
      });
    }
  };

  const handleOnSelect = (selectElement) => (item) => {
    setVertragsDetails({ ...vertragsDetails, [selectElement]: item });
  };

  const handleOnChange = (e) => {
    console.log("e", e.target.name, e.target.value);
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setVertragsDetails({ ...vertragsDetails, [e.target.name]: value });
  };

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      subHeaderTitle="Vertragsdetails"
      rightBtnName="zum Portfoliovorschlag"
      infoText={INFO_TEXTS.VERTRAGS_DETAILS}
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
      onComparisonBtnClick={onComparison}
      rightBtnDisabled={rightBtnDisabled}
      comparisonButtonHidden={!hasPermission("comparison")}>
      <Box sx={{ pb: "30px", marginLeft: "30px", marginRight: "30px", width: "60%" }}>
        <SelectComponent
          disabled={hasExcursionValue}
          label="Versicherer/Tarif *"
          name="versichererTarif"
          value={vertragsDetails.versichererTarif}
          onChange={handleOnSelect("versichererTarif")}
          options={tarifList}
        />
        <SelectComponent
          disabled={hasExcursionValue}
          label="Beitragszahlweise *"
          name="zahlweise"
          value={vertragsDetails.zahlweise}
          width="250px"
          onChange={handleOnSelect("zahlweise")}
          options={zahlweiseList}
        />
        <SelectComponent
          disabled={hasExcursionValue}
          label={`${zahlweiseLabel} *`}
          info={
            <Tooltip title={"Beachten Sie die Mindestlaufzeiten des Tarifs"} arrow>
              <IconButton sx={{ ml: "5px", p: "4px" }}>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
          name="jahren"
          value={vertragsDetails.jahren}
          width="250px"
          onChange={handleOnSelect("jahren")}
          options={beitragszahldauerOptions}
        />
        <InputComponent
          disabled={hasExcursionValue}
          isNumberFormat={true}
          label="Beitragshöhe *"
          name="beitragshohe"
          placeholder={minBeitragLabel}
          value={vertragsDetails.beitragshohe}
          error={!vertragsDetails.beitragshohe || vertragsDetails.beitragshohe < minBeitragValue}
          onChange={handleOnChange}
          styles={{ width: "250px" }}
        />
        {canSeeSicherungsvermoegen(userLicence) && (
          <SwitchComponent
            label="Sicherungsvermögen"
            info={
              <Tooltip title={"Mit der Aktivierung der Funktion „Sicherungsvermögen“ werden insbesondere Rentenfonds teilweise durch das Sicherungsvermögen ersetzt."} arrow>
                <IconButton sx={{ ml: "5px", p: "4px" }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            }
            name="nutze_sicherungsvermoegen"
            checked={vertragsDetails.nutze_sicherungsvermoegen}
            disabled={!canUseSicherungsvermoegen(kundenProfilDisplay, vertragsDetails.versichererTarif, esg) || hasExcursionValue}
            onChange={handleOnChange}
          />
        )}
        <InputComponent disabled={!user} label="Vorgangsnummer" name="vorgangsnummer" value={vertragsDetails.vorgangsnummer} onChange={handleOnChange} />
        <InputComponent disabled={!user} label="Vertragsnummer" name="vertragsnummer" value={vertragsDetails.vertragsnummer} onChange={handleOnChange} />
        <InputComponent disabled={!user} label="Name" name="name" value={vertragsDetails.name} onChange={handleOnChange} />
        <InputComponent disabled={!user} label="Vorname" name="vorname" value={vertragsDetails.vorname} onChange={handleOnChange} />
        <br />* Pflichtfeld
      </Box>
    </MainLayout>
  );
};

export default Vertragsdetails;
